/* stylelint-disable declaration-no-important */
.mdc-tab {
	padding-inline: 6px !important;
	--mdc-tab-indicator-active-indicator-color: var(--primary-contrast-color);
	--mat-tab-header-active-label-text-color: var(--primary-contrast-color);
	--mat-tab-header-active-hover-indicator-color: var(--primary-contrast-hover-color);
	--mat-tab-header-active-hover-label-text-color: var(--primary-contrast-hover-color);
	--mat-tab-header-active-focus-indicator-color: var(--primary-contrast-hover-color);
	--mat-tab-header-active-focus-label-text-color: var(--primary-contrast-hover-color);
}

mat-tab-group {
	height: 100%;
}

.mat-mdc-tab-body-wrapper {
	height: 100%;
}
