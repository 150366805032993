button.primary,
a.primary {
	font-size: 1rem;
	text-decoration: none;
	padding: 10px;
	background-color: var(--primary-color);
	border: none;
	font-weight: bold;
	color: var(--primary-contrast-color);
	transition:
		background-color,
		opacity 300ms ease-in-out;
}

button.primary:hover,
a.primary:hover {
	background-color: var(--primary-light-color);
}

button.primary:active,
a.primary:active {
	background-color: var(--primary-dark-color);
}

button.primary:disabled,
a.primary:disabled {
	opacity: 0.8;
}

button.icon-button {
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 0;
	border: 0;
	color: inherit;
	background-color: transparent;
}

.ygm-button {
	min-width: max-content;
	max-width: 400px;
	padding: 15px 24px;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: none;
	background-color: var(--primary-contrast-color);
	color: var(--font-secondary-color);
	height: fit-content;
	min-height: 50px;

	/* Typography. */
	font-family: "Inter", sans-serif;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.ygm-button:not(:disabled):hover {
	background-color: var(--primary-contrast-hover-color);
}

.ygm-button_outlined-dark {
	border: 2px solid var(--secondary-color);
	color: var(--secondary-color);
	background-color: var(--primary-theme-color);
}

.ygm-button_outlined-dark:not(:disabled):hover {
	border-color: var(--secondary-hover-color);
	color: var(--secondary-hover-color);
	background-color: var(--primary-theme-color);
}

.ygm-button_outlined-light {
	border: 2px solid var(--primary-contrast-color);
	color: var(--primary-contrast-color);
	background-color: var(--primary-theme-color);
}

.ygm-button_outlined-light:not(:disabled):hover {
	border-color: var(--primary-contrast-hover-color);
	color: var(--primary-contrast-hover-color);
	background-color: var(--primary-theme-color);
}

.ygm-button_text {
	color: var(--primary-contrast-color);
	background-color: transparent;
}

.ygm-button_text:not(:disabled):hover {
	color: var(--primary-contrast-hover-color);
	background-color: transparent;
}

.ygm-button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
	color: var(--border-color);
	border-color: var(--border-color);
}

.ygm-button_transparent {
	background-color: transparent;
}

.ygm-button_transparent:not(:disabled):hover {
	background-color: transparent;
}

.ygm-button_large {
	min-width: 190px;
}

.infinite-scroll-button {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: none;
	background: none;
	cursor: pointer;
	gap: 10px;
}

.infinite-scroll-button:hover .mat-icon {
	color: var(--primary-contrast-hover-color);
}

.infinite-scroll-button .mat-icon {
	height: 40px;
	width: 40px;
	color: var(--primary-contrast-color);
}

button.export-button {
	font-size: 16px;
	color: var(--primary-contrast-color);
	cursor: pointer;
}
