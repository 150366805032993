input.ygmpw-input.ygmpw-input {
	border: none;
	background-color: var(--input-color);
	padding-left: 20px;
	font-size: 16px;
	min-height: 50px;
}

input.ygmpw-input::placeholder {
	font-size: 16px;
	opacity: 0.5;
}
