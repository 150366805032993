.custom-dialog > mat-dialog-container {
	--mdc-dialog-container-color: transparent;
	--mdc-dialog-container-elevation: var(--primary-theme-color);
}

.dialog__actions_large {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
	box-shadow: none;
}
