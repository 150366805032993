input[type="checkbox"] {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	margin: 0;
	cursor: pointer;
}

input[type="checkbox"]:checked {
	background: var(--primary-contrast-color) no-repeat center center;
}

label:has(input[type="checkbox"]) {
	display: flex;
	align-items: center;
	width: fit-content;
	gap: 10px;
	cursor: pointer;
}

input[type="checkbox"].mdc-checkbox__native-control {
	opacity: 1;
	width: 18px;
	height: 18px;
	position: initial;
}

.mdc-checkbox__ripple,
.mdc-checkbox .mdc-checkbox__background {
	display: none;
}
