h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

.title,
.section-title {
	margin-bottom: 10px;
}

.page-title {
	margin-bottom: 20px;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}

.header__search {
	justify-self: flex-end;
	max-width: 255px;
	min-height: 50px;
}

.tab-header {
	margin-top: 20px;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
}

.tab-header__actions {
	display: flex;
	align-items: center;
}
