/* stylelint-disable declaration-no-important */
.ygm-autocomplete-menu.mat-mdc-autocomplete-panel {
	position: relative !important;
	top: 3px;
	border-radius: var(--border-radius) !important;
}

.ygm-autocomplete-menu.mat-mdc-autocomplete-panel .option__wrapper .mat-icon {
	color: var(--primary-contrast-color);
}
