.typography-label {
	font-family: "Inter", sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 30px;
}

.typography-title {
	font-family: "Inter", sans-serif;
	font-weight: 300;
	font-size: 24px;
	line-height: 40px;
}
