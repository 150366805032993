::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	display: block;
	overflow: auto;
	z-index: 1;
}

::-webkit-scrollbar-track {
	background-color: var(--secondary-theme-color);
	border-radius: var(--border-radius);
}

::-webkit-scrollbar-thumb {
	background-color: var(--primary-contrast-color);
	border-radius: 30px;
	background-clip: content-box;
}
