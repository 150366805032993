input[type="radio"] {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	margin: 0;
	border: 3px solid var(--input-color);
	border-radius: 50%;
	background-color: var(--input-color);
	cursor: pointer;
}

input[type="radio"]:checked {
	background: var(--primary-contrast-color) no-repeat center center;
}

label:has(input[type="radio"]) {
	display: flex;
	align-items: center;
	width: fit-content;
	gap: 10px;
	cursor: pointer;
}
