:root {
	--primary-theme-color: #ffffff;
	--secondary-theme-color: #e0e0e0;
	--tertiary-theme-color: #eeeeee;

	--positive-color: #3cba74;
	--warning-color: #eab93b;
	--error-color: #d80730;

	--message-color: #b0a14e;
	--note-color: #d2b755;
	--note-color-light: #fbf9f0;

	--box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);

	--primary-table-color-light: #f5f5f5;
	--primary-table-color-dark: #ebebeb;

	--table-group-header-color: #555c68;

	--expansion-background-color: #f7fafb;

	--border-radius: 10px;
	--border-color: #c4c4c4;
	--border-color-light: #eeeeee;
	--border-color-dark: #777777;

	--form-width: 820px;
	--list-padding-left: 20px;
}
