.ygm-select {
	width: 100%;
	max-height: 50px;
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
	min-height: 50px;
}

.ygm-select:focus:not(.mat-mdc-select-disabled) {
	outline: 2px solid var(--primary-contrast-color);
}

.ygm-select:not(.mat-mdc-select-disabled) {
	background-color: var(--font-secondary-color);
}

.ygm-select.mat-mdc-select {
	display: flex;
}

.ygm-select .mat-mdc-select-placeholder {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	opacity: 0.5;
}

.ygm-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
.ygm-select-menu .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.ygm-select-menu .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
	color: var(--element-contrast-color);
}

.mat-mdc-select-trigger {
	padding: 10px;
}

.ygm-select-menu .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
	background: var(--element-contrast-color);
}

.cdk-overlay-pane .ygm-select-menu.mat-mdc-select-panel {
	border-radius: var(--border-radius);
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) .mdc-menu-surface.mat-mdc-select-panel.ygm-select-menu {
	border-top-left-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);
}

.mat-mdc-select-value-text {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.mat-mdc-select-disabled .mat-mdc-select-arrow-wrapper {
	display: none;
}
