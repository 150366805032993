:root {
	--font-primary-color: #000000dc;
	--font-secondary-color: #ffffff;

	--icon-primary-color: #82bd41;

	--background-primary-color: #f7f8fc;
	--background-primary-contrast-color: #282b30;

	--element-contrast-color: #82bd41;

	/** Prevalent color in the application */
	--primary-color: #282b30;
	--primary-contrast-color: #82bd41;
	--primary-contrast-hover-color: color-mix(in srgb, var(--primary-contrast-color) 90%, black);
	--primary-light-color: #282b30e6;

	--secondary-color: #949699;

	--accent-color: #0ba1d6;

	--accent-gradient-color: linear-gradient(var(--accent-color), color-mix(in srgb, var(--accent-color) 60%, black));

	--input-color: #f7f8fc;
	--background-color: #ffffff;

	--error-color: red;
}
