.table {
	width: 100%;
}

.table__cell {
	border: 1px solid var(--primary-theme-color);
}

.table__header {
	color: var(--font-secondary-color);
	padding-inline: 30px;
}

.table .mat-column-actions {
	width: 5%;
}

.table__icons {
	display: flex;
	gap: 5px;
	color: var(--primary-contrast-color);
}

.table__icon-link {
	color: var(--primary-contrast-color);
}

.table__icons .mat-icon {
	cursor: pointer;
}

.table__empty-cell {
	text-align: center;
	padding: 20px;
}

.table__cell.mat-column-selection {
	width: 55px;
	padding-inline: 0;
}

.table .table__row.cdk-drag-placeholder {
	z-index: 1000;
	position: relative;
}

/** Drag preview is disabled due to incorrect styles behavior. Instead is used placeholder with animations. **/
.cdk-drag-preview {
	display: none;
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.table.cdk-drop-list-dragging .table__row {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.table__edit-panel {
	height: 0;
	transition: height ease 200ms;
}

.table__edit-panel_expanded {
	height: 120px;
}

.mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper {
	padding: 0;
}

.mat-mdc-paginator .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
	padding: 0;
}

.mat-mdc-paginator-container {
	margin-top: 15px;
}
