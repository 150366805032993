@import "./variables.css";
@import "./typography.css";
@import "./elements/index.css";
@import "./material/index.css";
@import "./utils.css";
@import "./grid.css";

* {
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
		"Helvetica Neue", sans-serif;
}

html,
body {
	height: 100%;
	background: var(--background-color);
	color: var(--background-contrast-color);
	margin: 0;
}

.dynamic-content ul,
.dynamic-content ol {
	padding-left: var(--list-padding-left);
}

.dynamic-content p {
	margin: 0;
}

.dynamic-content p:empty {
	margin: 1em;
}

.validation-error-message {
	display: block;
	min-height: 0.8rem;
	margin: 2px 0 4px 0;
	line-height: 1;
	color: var(--error-color);
	font-size: 0.8rem;
	font-weight: bold;
}

/* Global animations */
@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
