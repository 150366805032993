input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="datetime-local"],
input[type="time"],
input[type="date"],
input:not([type]),
textarea {
	width: 100%;
	padding: 10px;
	border-radius: var(--border-radius);
	border: 1px solid var(--border-color);
}

input:focus {
	outline-color: var(--primary-contrast-color);
}

.ygm-input,
.ygm-input::placeholder {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.ygm-input {
	min-height: 50px;
}

.ygm-input::placeholder {
	opacity: 0.5;
}

input[type="search"] {
	padding: 10px;
	background-image: url("/assets/icons/search.svg");
	background-color: var(--primary-table-color-light);
	background-size: 1.3rem;
	background-position: right 10px center;
	background-repeat: no-repeat;
	padding-right: calc(20px + 1rem);
	min-width: 250px;
}
