.form_normal {
	max-width: var(--form-width);
}

.form__row {
	display: flex;
	gap: 20px;
	align-items: center;
}

.form__checkbox {
	margin-bottom: 15px;
}

.row__field {
	width: 50%;
}

.row__field_small {
	width: calc(35% - 20px);
}

.row__field_tiny {
	width: 15%;
}

.form__actions {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
}

.row__uploader_normal {
	height: 300px;
}

.row__uploader_small {
	height: 200px;
}

.form__button-group {
	display: flex;
	gap: 20px;
}
