.mdc-tab {
	--mdc-secondary-navigation-tab-container-height: 80px;
	border-radius: 10px;
}

.mdc-tab:not(.mdc-tab--active) {
	--mat-tab-header-label-text-weight: 400;
	background: linear-gradient(var(--tertiary-theme-color), var(--primary-theme-color));
}

.mat-mdc-tab-labels {
	gap: var(--tabs-gap);
}

mat-tab-group {
	height: inherit;
}
