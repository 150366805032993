.divider {
	width: 100%;
	border: 1px solid var(--border-color-light);
	margin-top: 20px;
	margin-bottom: 30px;
}

.divider_dotted {
	border-style: dotted;
}

.divider_dashed {
	border-style: dashed;
}

.vertical-divider {
	display: inline;
	height: 30px;
	border-right: 1px solid var(--border-color);
	border-left: 0;
	margin: 0 20px;
}

.vertical-divider_dashed {
	border-right-style: dashed;
}

.vertical-divider_dotted {
	border-right-style: dotted;
}
