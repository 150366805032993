.mat-expansion-panel-header {
	/* Need important to override angular material component. */
	/* stylelint-disable-next-line declaration-no-important */
	padding: 10px 0 10px 30px !important;
	box-sizing: content-box;
	box-shadow: 0px 5px 5px -4px rgba(34, 60, 80, 0.3);
}

.mat-expansion-panel-header.mat-focus-indicator {
	border-radius: 0;
}

.mat-expansion-panel-body {
	/* Need important to override angular material component. */
	/* stylelint-disable-next-line declaration-no-important */
	padding: 0 !important;
}

.mat-expansion-panel .mat-action-row {
	justify-content: flex-start;
	gap: 40px;
	padding: 20px 30px;
}

.mat-expansion-panel-header-title {
	width: 100%;
}
