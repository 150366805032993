input[type="checkbox"] {
	border: 3px solid var(--input-color);
	background-color: var(--input-color);
}

input[type="checkbox"]:checked {
	border-radius: 5px;
}

input[type="checkbox"].checkbox_light {
	border-color: var(--primary-theme-color);
	outline: 2px solid var(--border-color);
}

input[type="checkbox"].checkbox_light:not(:checked) {
	background-color: var(--primary-theme-color);
}
