.col-1 {
	grid-column: 1 / 2;
}

.col-2 {
	grid-column: 2 / 3;
}

.col-3 {
	grid-column: 3 / 4;
}

.col-4 {
	grid-column: 4 / 5;
}

.col-1-2 {
	grid-column: 1 / 3;
}

.col-1-3 {
	grid-column: 1 / 4;
}

.col-2-4 {
	grid-column: 2 / 5;
}

.col-3-4 {
	grid-column: 3 / 5;
}

.full-row {
	grid-column: 1 / -1;
}

.row-2-4 {
	grid-row: 2 / 5;
}

.row-2-3 {
	grid-row: 2 / 4;
}

.row-2 {
	grid-row: 2;
}

.row-3-4 {
	grid-row: 3 / 5;
}

.row-3 {
	grid-row: 3;
}

.row-4 {
	grid-row: 4;
}

.row-4-5 {
	grid-row: 4 / 6;
}

.row-3-5 {
	grid-row: 3 / 6;
}
