.ygm-select.ygmpw-select {
	border: none;
}

.ygm-select.ygmpw-select:not(.mat-mdc-select-disabled) {
	background-color: var(--input-color);
}

.ygm-select.ygmpw-select .mat-mdc-select-placeholder,
.ygm-select.ygmpw-select .mat-mdc-select-value-text {
	font-size: 16px;
	padding-left: 10px;
}

.ygm-select.mat-mdc-select-disabled {
	background-color: var(--input-color);
}

.ygm-select.mat-mdc-select-disabled .mat-mdc-select-value-text {
	color: var(--font-primary-color);
}
