.typography-display-large {
	font-family: "Inter", sans-serif;
	font-weight: 900;
	font-size: 60px;
	line-height: 60px;
}

.typography-display {
	font-family: "Inter", sans-serif;
	font-weight: 900;
	font-size: 40px;
	line-height: 50px;
}

.typography-headline {
	font-family: "Inter", sans-serif;
	font-weight: 300;
	font-size: 28px;
	line-height: 36px;
}

.typography-header {
	font-family: "Inter", sans-serif;
	font-weight: 800;
	font-size: 24px;
	line-height: 32px;
}

.typography-subheader {
	font-family: "Inter", sans-serif;
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
}

.typography-body-large {
	font-family: "Inter", sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
}

.typography-body {
	font-family: "Inter", sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 30px;
}

.typography-body-small {
	font-family: "Inter", sans-serif;
	font-weight: 400;
	font-size: 12px;
	line-height: 24px;
}
