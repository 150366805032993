.mat-datepicker-toggle {
	position: absolute;
	right: 0;
	top: 0;
}

.mat-date-range-input-container {
	display: flex;
	justify-content: center;
	height: 30px;
}

.mat-date-range-input-container .mat-date-range-input-inner {
	border: none;
	padding: 0;
	width: min-content;
}

.mat-mdc-icon-button .mat-mdc-button-ripple,
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
	display: none;
}

.mat-datepicker-toggle .mat-icon {
	color: var(--primary-contrast-color);
}

.mat-calendar-body-cell .mat-calendar-body-selected {
	background-color: var(--primary-contrast-color);
}

.mat-calendar-body-cell
	.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
	border-color: var(--primary-contrast-color);
}

.mat-calendar-body-cell.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
	background-color: var(--primary-contrast-color);
	opacity: 0.5;
}
